import { Grid } from 'common/layouts/grid/Grid';
import { GridColumn } from 'common/layouts/grid/GridColumn';
import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { colors } from 'component-library/styles/colors';
import { SEO } from 'components/SEO';
import { StaticImage } from 'gatsby-plugin-image';
import { Footer } from 'page-components/shared/footer';
import { WelcomeWrapper } from 'page-components/welcome/welcomeUtils';
import React, { FC, useEffect } from 'react';

interface ReferralProps {}

const Referral: FC<ReferralProps> = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `!function(){var a=window.VL=window.VL||{};return a.instances=a.instances||{},a.invoked?void(window.console&&console.error&&console.error("VL snippet loaded twice.")):(a.invoked=!0,void(a.load=function(b,c,d){var e={};e.publicToken=b,e.config=c||{};var f=document.createElement("script");f.type="text/javascript",f.id="vrlps-js",f.defer=!0,f.src="https://app.viral-loops.com/client/vl/vl.min.js";var g=document.getElementsByTagName("script")[0];return g.parentNode.insertBefore(f,g),f.onload=function(){a.setup(e),a.instances[b]=e},e.identify=e.identify||function(a,b){e.afterLoad={identify:{userData:a,cb:b}}},e.pendingEvents=[],e.track=e.track||function(a,b){e.pendingEvents.push({event:a,cb:b})},e.pendingHooks=[],e.addHook=e.addHook||function(a,b){e.pendingHooks.push({name:a,cb:b})},e.$=e.$||function(a){e.pendingHooks.push({name:"ready",cb:a})},e}))}();var campaign=VL.load("${process.env.GATSBY_VIRAL_LOOPS_CAMPAIGN_ID}",{autoLoadWidgets:!0});`;
    document.body.appendChild(script);
  }, []);

  return (
    <Layout>
      <Navigation />
      <PageWrapper background={colors.greenLight}>
        <WelcomeWrapper>
          <Grid cols={2} gap={3} desktopGap={10}>
            <GridColumn span={2} desktopSpan={1} data-vl-widget="embedForm" />
            <GridColumn span={2} desktopSpan={1}>
              <StaticImage src="../components/pages/images/hero.png" alt="" />
            </GridColumn>
          </Grid>
        </WelcomeWrapper>
        <Footer />
      </PageWrapper>
    </Layout>
  );
};

export default Referral;

export const Head = () => (
  <SEO
    title="Look Forward To More In Retirement"
    description="Retirable® gives adults 50+ retirement income management and guidance to help them confidently maintain a comfortable lifestyle."
  />
);
